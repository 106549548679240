var getURLVar = require('./get-url-var.js');

var cart = {
    updateCartBox: function () {
        $('#cart-items-holder').load('index.php?route=common/cart/info #cart-items');
    },
    add: function(product_id, quantity) {
        $.ajax({
            url: 'index.php?route=checkout/cart/add',
            type: 'post',
            data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
            dataType: 'json',
            success: function(json) {
                $('.alert, .text-danger').remove();

                if (json.redirect) {
                    location = json.redirect;
                }

                if (json.success) {
                    $('#content').parent().before('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json.success + '<button type="button" class="close" data-dismiss="alert">&times;</button></div>');

                    // Need to set timeout otherwise it wont update the total
                    setTimeout(function () {
                        $('#cart-total').html(json.total);
                    }, 100);

                    // $('html, body').animate({ scrollTop: 0 }, 'slow');
                    cart.updateCartBox();
                }
            }
        });
    },
    update: function(key, quantity) {
        $.ajax({
            url: 'index.php?route=checkout/cart/edit',
            type: 'post',
            data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
            dataType: 'json',
            success: function(json) {
                // Need to set timeout otherwise it wont update the total
                setTimeout(function () {
                    $('#cart-total').html(json.total);
                }, 100);

                if (getURLVar('route').match('checkout/')) {
                    location = 'index.php?route=checkout/cart';
                } else {
                    cart.updateCartBox();
                }
            }
        });
    },
    remove: function(key) {
        $.ajax({
            url: 'index.php?route=checkout/cart/remove',
            type: 'post',
            data: 'key=' + key,
            dataType: 'json',
            success: function(json) {
                // Need to set timeout otherwise it wont update the total
                setTimeout(function () {
                    $('#cart-total').html(json.total);
                }, 100);

                if (getURLVar('route').match('checkout/')) {
                    location = 'index.php?route=checkout/cart';
                } else {
                    cart.updateCartBox();
                }
            }
        });
    }
};

module.exports = cart;
