require('bootstrap-datepicker');
require('bootstrap-datepicker-cs');

var Datepicker = function(container) {
    this.container = container;
    this.input = container.find('input');

    this.init();
};

Datepicker.prototype.init = function() {
    this.container.on('click', 'button', $.proxy(this.handleButtonClick, this));

    this.input.datepicker({
        language: 'cs'
    });
};

Datepicker.prototype.handleButtonClick = function(e) {
    e.preventDefault();

    this.input.datepicker('show');
};

module.exports = Datepicker;
