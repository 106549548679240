var Counter = function(container) {
    this.container = container;
    this.input = this.container.find('input');
    this.value = Counter.MIN;

    this.container
        .on('click', '.js-inc', $.proxy(this.handleIncreaseClick, this))
        .on('click', '.js-dec', $.proxy(this.handleDecreaseClick, this));
};

Counter.MIN = 1;

Counter.prototype.handleIncreaseClick = function(e) {
    var val = this.getValue();

    e.preventDefault();
    this.setValue(++val);
};

Counter.prototype.handleDecreaseClick = function(e) {
    var val = this.getValue();

    e.preventDefault();
    this.setValue(--val);
};

Counter.prototype.getValue = function() {
    return this.sanitize(this.input.val());
};

Counter.prototype.setValue = function(value) {
    var val = this.sanitize(value);

    this.value = val;
    this.input.val(val);
    this.input.trigger('change');
};

Counter.prototype.sanitize = function(value) {
    var val = parseInt(value, 10);

    if (!$.isNumeric(val)) {
        val = this.value;
    }

    if (val < Counter.MIN) {
        val = Counter.MIN;
    }

    return val;
};

module.exports = Counter;
