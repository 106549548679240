var CartUpdate = function(container) {
    this.container = container;
    this.quantityInput = this.container.find('.js-cart-update-input');

    this.container.on('change', '.js-cart-update-input', $.proxy(this.handleQuantityChange, this));
};

CartUpdate.prototype.handleQuantityChange = function(e) {
    var val = $(e.currentTarget).val();

    this.container.submit();
};

module.exports = CartUpdate;
