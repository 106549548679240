var bowerPath = '../../../../../../bower/';

require('bs-alert');
require('bs-dropdown');
require('bs-tooltip');
require('bs-tab');
require('bs-collapse');
require('bs-modal');
require('bs-button');
require('magnific-popup');

var cart = require('./cart.js');
var voucher = require('./voucher.js');
var Datepicker = require('./datepicker.js');
var attachAgreeModal = require('./attach-agree-modal.js');
var Counter = require('./counter.js');
var CartUpdate = require('./cart-update.js');

var app = {};

app.run = function(config) {
    this.config = config || {};

    this.cart = cart;
    this.voucher = voucher;
    this.datepicker = app.factory(Datepicker, [$('.js-datepicker')]);
    this.counter = app.factory(Counter, [$('.js-counter')]);
    this.cartUpdate = app.init(CartUpdate, [$('#cart-form')]);

    $('[data-toggle="tooltip"]').tooltip({container: 'body'});

    // Opencart junk
    $('.text-danger').closest('.form-group').addClass('has-error');
    attachAgreeModal();
    window.cart = app.cart;
    window.voucher = app.voucher;

    // BS dropdown disallow clickthrough
    $(document).on('click.bs.dropdown.data-api', '.dropdown-menu', function(e) {
        e.stopPropagation();
    });
};

app.init = function(Klass, args) {
    var container = args[0];
    var argsCount = args.length;

    if (container.length) {
        if (argsCount === 1) {
            return new Klass(container);
        } else if (argsCount === 2) {
            return new Klass(container, args[1]);
        } else if (argsCount === 3) {
            return new Klass(container, args[1], args[2]);
        } else if (argsCount === 4) {
            return new Klass(container, args[1], args[2], args[3]);
        }
    }
};

app.factory = function(Klass, args) {
    var containers = args[0];
    var containersCount = containers.length;
    var argsCount = args.length;
    var returnArray = [];

    if (containersCount) {
        for (var i = 0; i < containersCount; i++) {
            if (argsCount === 1) {
                returnArray.push(new Klass($(containers.get(i))));
            } else if (argsCount === 2) {
                returnArray.push(new Klass($(containers.get(i)), args[1]));
            } else if (argsCount === 3) {
                returnArray.push(new Klass($(containers.get(i)), args[1], args[2]));
            } else if (argsCount === 4) {
                returnArray.push(new Klass($(containers.get(i)), args[1], args[2], args[3]));
            }
        }
    }

    return returnArray;
};

window.app = app;
